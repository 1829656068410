import React, { useState, useContext, useEffect } from "react";
import "./MainSidebar.css";
import { AuthContext } from "../../../../contexts/AuthContext";
import { useNavigate, useLocation } from "react-router-dom";
import * as links from "../../../../utils/links";

const Sidebar = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const { setDataUser, loading, authInfo } = useContext(AuthContext);
  let navigate = useNavigate();
  const { dataUser } = authInfo;

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isOpen && !event.target.closest('.sidebar')) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);


  return (
    <div style={{display: 'flex'}}>
      <aside className={`sidebar ${isOpen ? "open" : ""}`}>
        <button className={`toggle ${isOpen ? "open" : ""}`}type="button" onClick={toggleOpen}>
            <i class="bi bi-chevron-right"></i>
        </button>
        <div className="inner">
          <div className="header">
            {isOpen ? (
                <img src="/logo.svg" alt="Logo" className="logo" />
            ) : (
              <button className="toggleButton" type="button" onClick={toggleOpen}>
                <i className="bi bi-list large-icon"></i>
              </button>
            )}
            <h1 className={`header-title ${isOpen ? "open" : ""}`}>LC Sistemas</h1>
          </div>
          <div className="user-card">
            <i class="bi bi-person-circle"></i>
            <span className={`user-name ${isOpen ? "open" : ""}`}>{dataUser?.name}</span>
          </div>
          <nav className="menu">
            {
              dataUser?.key === "CLIENT"
              ? 
              <button className={location.pathname === links.DASHBOARD_FORNECEDOR ? "active" : ""} type="button" onClick={() => {
                navigate(
                  links.DASHBOARD_FORNECEDOR
                );
              }}>
                <i class="bi bi-hdd-stack large-icon"></i>
                <span>Máquinas</span>
              </button>
              :
              <button className={location.pathname === links.DASHBOARD_CLIENTES ? "active" : ""} type="button" onClick={() => {
                navigate(
                  links.DASHBOARD_CLIENTES
                );
              }}>
                <i class="bi bi-people large-icon"></i>
                <span>Clientes</span>
              </button>
            }
            {
              dataUser?.key === "ADMIN" ?
              <button className={location.pathname === links.LISTA_MAQUINAS ? "active" : ""} type="button" onClick={() => {
                navigate(
                  links.LISTA_MAQUINAS
                );
              }}>
                <i class="bi bi-hdd-stack large-icon"></i>
                <span>Máquinas</span>
              </button>
              :
              ''
            }
            {
              dataUser?.key === "ADMIN" ?
              <button className={location.pathname === links.MENSALIDADES_ADM_LIST ? "active" : ""} type="button" onClick={() => {
                navigate(
                  links.MENSALIDADES_ADM_LIST
                );
              }}>
                <i class="bi bi-qr-code large-icon"></i>
                <span>Mensalidades</span>
              </button>
              :
              ''
            }
            {
              dataUser?.key === "CLIENT" ?
              <button className={location.pathname === links.NEW_PASSWORD ? "active" : ""} type="button" onClick={() => {
                navigate(
                  links.NEW_PASSWORD
                );
              }}>
                <i class="bi bi-key large-icon"></i>
                <span>Trocar Senha</span>
              </button>
              :
              ''
            }
            {
              dataUser?.key === "CLIENT" ?
              <button className={location.pathname === links.CLIENTE_PAGAMENTOS ? "active" : ""} type="button" onClick={() => {
                navigate(
                  links.CLIENTE_PAGAMENTOS
                );
              }}>
                <i class="bi bi-credit-card-2-front large-icon"></i>
                <span>Pagamentos</span>
              </button>
              :
              ''
            }
            {
              dataUser?.key === "CLIENT" ?
              <button className={location.pathname === links.CLIENTE_MENSALIDADES ? "active" : ""} type="button" onClick={() => {
                navigate(
                  links.CLIENTE_MENSALIDADES
                );
              }}>
                <i class="bi bi-qr-code large-icon"></i>
                <span style={{flexWrap: 'wrap'}}>Mensalidades</span>
              </button>
              :
              ''
            }
            <button type="button" onClick={() => {
                navigate(
                  dataUser?.key === "CLIENT" ? links.SIGNIN : links.ADMIN_SIGNIN
                );
                setDataUser(null);
              }}>
              <i class="bi bi-escape large-icon"></i>
              <span>Sair</span>
            </button>
          </nav>
        </div>
      </aside>
    </div>
  );
};

export default Sidebar;
