import React from 'react';
import "./RelatorioDispAdmin.css";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const DisponibilidadeChart = ({ response, month }) => {

    const monthsOptions = [
        { label: "Janeiro", month: '01'},
        { label: "Fevereiro", month: '02' },
        { label: "Março", month: '03'},
        { label: "Abril", month: '04'},
        { label: "Maio", month: '05'},
        { label: "Junho", month: '06'},
        { label: "Julho", month: '07'},
        { label: "Agosto", month: '08' },
        { label: "Setembro", month: '09' },
        { label: "Outubro", month: '10'},
        { label: "Novembro", month: '11'},
        { label: "Dezembro", month: '12'},
      ];

    // const numeroMes = monthsOptions.find((item) => item.label === month)?.month;
    const numeroMes = monthsOptions.find((item) => {
        const monthName = month?.split(" ")[0]; // Extrai apenas o nome do mês
        return item.label === monthName; // Compara apenas o nome do mês
      })?.month;
      
    
    // Função para montar a matriz com base nos dados filtrados
    const createMatrixFromResponse = (filteredData) => {
        const rows = 48; // Intervalos de 30 minutos
        const cols = 31; // Dias do mês
        const matrix = Array.from({ length: rows }, () =>
            Array.from({ length: cols }, () => 0) // Inicializa a matriz com 0
        );

        filteredData.forEach(item => {
            const [day, month, year] = item.data.split('/');
            const dayIndex = parseInt(day) - 1; // Converte para índice de 0
            const intervalIndex = getIntervalIndex(item.intervalo); // Obtém o índice do intervalo

            if (dayIndex >= 0 && dayIndex < cols && intervalIndex >= 0 && intervalIndex < rows) {
                matrix[intervalIndex][dayIndex] = 1; // Marca a célula correspondente
            }
        });
        
        return matrix;
    };

    const getTimeRange = (index) => {
        const startHour = Math.floor(index / 2);
        const startMinutes = (index % 2) * 30;
        const endHour = Math.floor((index + 1) / 2);
        const endMinutes = ((index + 1) % 2) * 30;

        const formatTime = (hour, minutes) => `${String(hour).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;

        return `${formatTime(startHour, startMinutes)}-${formatTime(endHour, endMinutes)}`;
    };
    // Função para converter o intervalo em índice da matriz
    const getIntervalIndex = (interval) => {
        const [start, end] = interval.split('-');
        const startHour = parseInt(start.split(':')[0]);
        const startMinutes = parseInt(start.split(':')[1]);
        // Cada intervalo de 30 minutos, então a linha é 0 para 00:00-00:30, 1 para 00:30-01:00, ..., 47 para 23:30-00:00
        return (startHour * 2) + (startMinutes / 30);
    };

    const matrix = createMatrixFromResponse(response); // Cria matriz a partir dos dados filtrados

    // Função para determinar a cor da célula
    const getCellColor = (value) => {
        return value === 1 ? 'rgba(0, 123, 255, 0.6)' : 'rgba(255, 255, 255, 1)'; // Azul se houver marcação, branco caso contrário
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            {
                numeroMes ? 
                <div style={{ border: '1px solid #ccc', borderRadius: '5px', overflow: 'hidden', width: 'fit-content', maxWidth: '90vw' }}>
                    <h2 style={{ textAlign: 'center', margin: '5px', fontSize: '0.7rem',fontWeight: '500' }}>{`Disponibilidade em ${month}`}</h2>
                    <div style={{ overflowY: 'auto' }}>
                        <table style={{ margin: '0 auto', fontSize: '0.8rem' }}>
                            <thead>
                                <tr>
                                    <th className="table-header-cell">Tempo/Dia</th>
                                    {[...Array(31)].map((_, index) => (
                                        <th key={index} className="table-header-cell">
                                            {index > 8 ? `${index + 1}/${numeroMes}` : `0${index + 1}/${numeroMes}`}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {matrix.map((row, y) => (
                                    <tr key={y}>
                                        <td className="time-cell">{getTimeRange(y)}</td>
                                        {row.map((value, x) => (
                                            <td
                                                key={x}
                                                style={{
                                                    backgroundColor: getCellColor(value),
                                                    border: '1px solid #ccc',
                                                    borderBottom: value === 1 ? 'none' : '1px solid #ccc',
                                                    borderTop: 'none',
                                                    padding: '2px',
                                                }}
                                            />
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                :
                ''
            }
        </div>
    );
};

export default DisponibilidadeChart;
