import React, { useContext, useEffect, useState } from "react";
import "./RelatorioDispAdmin.css";
import { Col, Row, Button, Select } from "antd";
import axios from "axios";
import { AuthContext } from "../../../../contexts/AuthContext";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import LoadingAction from "../../../../themes/LoadingAction/LoadingAction";
import moment from "moment";
import "moment/locale/pt-br";
import DisponibilidadeChart from "./RelatorioTable";
import * as links from "../../../../utils/links";

const { Option } = Select;
moment.locale("pt-br"); 

const RelatorioDispAdmin = (props) => {
  const { authInfo, setNotiMessage } = useContext(AuthContext);
  const { dataUser } = authInfo;
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();

  const { maquinaInfos, dataInicio, dataFim } = location.state;

  const token = authInfo?.dataUser?.token;
  const [responseList, setResponseList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Estado para armazenar as datas selecionadas
  const [selectedDate, setSelectedDate] = useState({
    dataInicio: "",
    dataFim: "",
  });

  const [selectedMonthForTable, setSelectedMonthForTable] = useState('');

  // const monthsOptions = [
  //   { label: "Janeiro", dataInicio: "2024-01-01T03:00:00.000Z", dataFim: "2024-02-01T03:00:00.000Z",month: '01'},
  //   { label: "Fevereiro", dataInicio: "2024-02-01T03:00:00.000Z", dataFim: "2024-03-01T03:00:00.000Z",month: '02' },
  //   { label: "Março", dataInicio: "2024-03-01T03:00:00.000Z", dataFim: "2024-04-01T03:00:00.000Z" ,month: '03'},
  //   { label: "Abril", dataInicio: "2024-04-01T03:00:00.000Z", dataFim: "2024-05-01T03:00:00.000Z" ,month: '04'},
  //   { label: "Maio", dataInicio: "2024-05-01T03:00:00.000Z", dataFim: "2024-06-01T03:00:00.000Z" ,month: '05'},
  //   { label: "Junho", dataInicio: "2024-06-01T03:00:00.000Z", dataFim: "2024-07-01T03:00:00.000Z" ,month: '06'},
  //   { label: "Julho", dataInicio: "2024-07-01T03:00:00.000Z", dataFim: "2024-08-01T03:00:00.000Z" ,month: '07'},
  //   { label: "Agosto", dataInicio: "2024-08-01T03:00:00.000Z", dataFim: "2024-09-01T03:00:00.000Z",month: '08' },
  //   { label: "Setembro", dataInicio: "2024-09-01T03:00:00.000Z", dataFim: "2024-10-01T03:00:00.000Z",month: '09' },
  //   { label: "Outubro", dataInicio: "2024-10-01T03:00:00.000Z", dataFim: "2024-11-01T03:00:00.000Z" ,month: '10'},
  //   { label: "Novembro", dataInicio: "2024-11-01T03:00:00.000Z", dataFim: "2024-12-01T03:00:00.000Z" ,month: '11'},
  //   { label: "Dezembro", dataInicio: "2024-12-01T03:00:00.000Z", dataFim: "2025-01-01T03:00:00.000Z" ,month: '12'},
  // ];
  const getMonthsOptions = () => {
    const monthsLabels = [
        "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
        "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
    ];

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth(); // 0-indexed (Janeiro é 0)
    const monthsOptions = [];

    for (let i = 6; i >= 0; i--) {
        const date = new Date(currentYear, currentMonth - i, 1);
        const label = monthsLabels[date.getMonth()];
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        
        const dataInicio = new Date(year, date.getMonth(), 1).toISOString();
        const dataFim = new Date(year, date.getMonth() + 1, 1).toISOString();

        monthsOptions.push({
            label: `${label} ${year}`,
            dataInicio,
            dataFim,
            month,
        });
    }

    return monthsOptions;
};

const monthsOptions = getMonthsOptions();

  

  
  const currentMonth = moment().format("MMMM YYYY"); // Formata para "Agosto 2024"
  const formattedCurrentMonth = currentMonth.charAt(0).toUpperCase() + currentMonth.slice(1);
  const currentDate = monthsOptions.find((month) => month.label === formattedCurrentMonth);

  const filteredMonthsOptions = monthsOptions.filter((month) => {
    return moment(month.label, "MMMM YYYY").isSameOrBefore(moment(currentMonth, "MMMM YYYY"));
  });
  

  useEffect(() => {
    if(selectedDate?.dataInicio !== ''){
      fetchData();
    }else{
      initialLoad();
    }
  }, [selectedDate]);

  const fetchData = async () => {
    try {
      setIsLoading(true);

      let body = {
        maquinaId: id,
        dataInicio: selectedDate.dataInicio,
        dataFim: selectedDate.dataFim,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_SERVIDOR}/monitoramento-adm`,
        body,
        {
          headers: {
            "x-access-token": token,
            "content-type": "application/json",
          },
        }
      );

      setResponseList(response?.data);
      setIsLoading(false);
    } catch (error) {
      setResponseList([]);
      setIsLoading(false);
      setNotiMessage({
        type: "error",
        message:`${error?.response.data.message}`,
      });
    }
  };

  const initialLoad = async () => {
    const currentMonth = moment().format("MMMM YYYY"); 
    const formattedCurrentMonth = currentMonth.charAt(0).toUpperCase() + currentMonth.slice(1);
    
    const selectedMonth = monthsOptions.find((month) => month.label === formattedCurrentMonth);
  
    try {
      setIsLoading(true);

      let body = {
        maquinaId: id,
        dataInicio: selectedMonth.dataInicio,
        dataFim: selectedMonth.dataFim,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_SERVIDOR}/monitoramento-adm`,
        body,
        {
          headers: {
            "x-access-token": token,
            "content-type": "application/json",
          },
        }
      );

      setResponseList(response?.data);
      
      setIsLoading(false);
    } catch (error) {
      setResponseList([]);
      setIsLoading(false);
      setNotiMessage({
        type: "error",
        message:`${error?.response?.data?.message}`,
      });
    }finally{
      setSelectedMonthForTable(currentDate?.label);
    }
  };

  // Função para lidar com a mudança do mês selecionado
  const handleMonthChange = (value) => {
    setSelectedMonthForTable(value);
    
    const selectedMonth = monthsOptions.find((month) => month.label === value);
    
    
    if (selectedMonth) {
      setSelectedDate({
        dataInicio: selectedMonth.dataInicio,
        dataFim: selectedMonth.dataFim,
      });
    }
  };

  return (
    <div>
      {isLoading && <LoadingAction />}
      <div className="Cliente_WarningMsgSpan">
        <span>{dataUser.warningMsg}</span>
      </div>
      <div className="Relatorio_main">
        <div
          style={{
            display: "flex",
          }}
        >
          <div className="Relatorio_staBlockTitle">
            <span>Relatório Máquina: {maquinaInfos.nome}</span>
            {/* <br />
            <span style={{ color: "grey", fontSize: "15px" }}>
              {maquinaInfos.nome}
            </span> */}
          </div>
        </div>
        <div style={{marginRight: '100px'}}>
          <Select
            defaultValue={currentDate.label}
            style={{ width: 150, marginRight: '10px'}}
            onChange={handleMonthChange}
          >
            {filteredMonthsOptions?.map((month) => (
              <Option key={month.label} value={month.label}>
                {month.label}
              </Option>
            ))}
          </Select>
          <Button
            style={{ width: 150}}
            onClick={() => {
              navigate(`${links.CLIENTES_MAQUINAS_FORNECEDOR_SEARCH}/${id}`, {
                state: location.state,
              });
            }}
          >
            VOLTAR
          </Button>
        </div>
      </div>
      <Row>
        <Col xs={24} md={22} lg={22} xl={22}>
          <DisponibilidadeChart response={responseList} month={selectedMonthForTable}/>
        </Col>
      </Row>
    </div>
  );
};

export default RelatorioDispAdmin;
