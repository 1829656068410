import { useState, useContext, useEffect } from "react";
import { OverlayTrigger, Tooltip, Modal} from "react-bootstrap";
import axios from "axios";
import { AuthContext } from "../../../../../contexts/AuthContext";
import "./MensalidadesAdmList.css";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import * as links from "../../../../../utils/links";
import moment from "moment";
import { Input, Button, Row } from "antd";
import checkImage from "../../../../../assets/images/image_check.png";
import MensalidadesTable from "../Table/MensalidadesListTable";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function MensalidadesAdmList() {

  const location = useLocation();
  const clienteInfo = location.state;

  const { setDataUser, authInfo} = useContext(AuthContext);
  const { dataUser } = authInfo;

  let navigate = useNavigate();
  const token = authInfo?.dataUser?.token;
  const [diaDoPagamento, setDiaDoPagamento] = useState("");
  const [paymentResponse, setPaymentResponse] = useState("");
  const [numeroParcelas, setNumeroParcelas] = useState("");
  const [valor, setValor] = useState();
  const [loadingTable, setLoadingTable] = useState(false);
  const [listMensals, setListMensals] = useState([]);
  const [dataFim, setDataFim] = useState(null);
  const [somaAtraso, setSomaAtraso] = useState("");
  const [proximaParcela, setProximaParcela] = useState("");
  const [dataVencimento, setDataVencimento] = useState("");
  const [showModal, setShowModal] = useState(false);  // Estado para controlar a visibilidade da modal
  const [copied, setCopied] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(false);
  const [paymentInterval, setPaymentInterval] = useState(null);
  const [referenceId, setReferenceId] = useState(null);

  useEffect(() => {
    getData();
  }, []);




  const handleShowModal = () => setShowModal(true);  // Função para abrir a modal
  const handleCloseModal = () => setShowModal(false);  // Função para fechar a modal

  const checkPaymentStatus = (referenceId) => {
    axios
      .get(`${process.env.REACT_APP_SERVIDOR}/verificar-cobranca/${referenceId}`, {
        headers: {
          "x-access-token": dataUser?.token,
          "content-type": "application/json",
        },
      })
      .then((response) => {
        const isPaid = response?.data?.pago; // Verifique se 'pago' está em 'response.data'
        console.log(isPaid);
        setPaymentStatus(isPaid); // Atualiza o status do pagamento
  
        if (isPaid) {
          toast.success("Pagamento confirmado!");
          clearInterval(paymentInterval); // Para o intervalo de verificação
          setReferenceId(null); // Reseta a referência
        }
      })
      .catch((err) => {
        console.error("Erro ao verificar o pagamento:", err);
      });
  };
  
  useEffect(() => {
    if (referenceId) {
      // Inicia o intervalo para checar o pagamento a cada 5 segundos
      const interval = setInterval(() => {
        checkPaymentStatus(referenceId);
      }, 5000);
  
      setPaymentInterval(interval); // Armazena o intervalo em estado
  
      // Limpeza do intervalo ao desmontar o componente
      return () => clearInterval(interval);
    }
  }, [referenceId]);

  useEffect(() => {
    if (paymentStatus) {
      const timeout = setTimeout(() => {
        handleCloseModal();
      }, 8000);

      return () => clearTimeout(timeout);
    }
  }, [paymentStatus]);




  const generatePayments = (diaDoPagamento, numeroParcelas, valor) => {
    let body = {
      diaDoPagamento: formatarParaMoeda(diaDoPagamento),
      numeroDeParcelas: formatarParaMoeda(numeroParcelas),
      valor: formatarParaMoeda(valor),
    }
      axios.post(`${process.env.REACT_APP_SERVIDOR}/gerarPagamentosParaCliente`,body,{
        headers: {
          "x-access-token": token,
          "content-type": "application/json",
        },
      }).then((res) => {
        toast.success(`${res.data.message}`);
        getData();
      }).catch((err) => {
        if ([401, 403].includes(err.response.status)) {
          toast.error("A sua sessão expirou, para continuar faça login novamente.")
          if(dataUser.key === 'ADMIN'){
            navigate(links.ADMIN_SIGNIN)
            setDataUser(null);
          }else{
            navigate(links.SIGNIN)
            setDataUser(null);
          }
        }
        toast.error(`${err?.response?.data?.error}`)
      });
    }

  const generatePayment = (id) => {
      axios.post(`${process.env.REACT_APP_SERVIDOR}/gerar-cobranca/${id}`,{
        headers: {
          "x-access-token": token,
          "content-type": "application/json",
        },
      }).then((res) => {
        setPaymentStatus(false);
        setPaymentResponse(res?.data);
        setReferenceId(res?.data?.payment_data?.external_reference);
        toast.success(`${res.data.status}`);
        handleShowModal();
      }).catch((err) => {
        if ([401, 403].includes(err.response.status)) {
          toast.error("A sua sessão expirou, para continuar faça login novamente.")
          if(dataUser.key === 'ADMIN'){
            navigate(links.ADMIN_SIGNIN)
            setDataUser(null);
          }else{
            navigate(links.SIGNIN)
            setDataUser(null);
          }
        }
        toast.error(`${err?.response?.data?.error}`);
      });
    }

  const updateTable = () =>{
    getData();

  }

  const getData = () => {
      setLoadingTable(true);
      axios
        .get(`${process.env.REACT_APP_SERVIDOR}/listar-cobrancas-adm`, {
          headers: {
            "x-access-token": token,
            "content-type": "application/json",
          },
        })
        .then((res) => {
          setLoadingTable(false);
          setSomaAtraso(res?.data?.somaAtraso);
          setProximaParcela(res?.data?.proximaParcela);
          setDataVencimento(res?.data?.dataVencimento);
          if (res.status === 200 && Array.isArray(res.data)) {
            setListMensals(res.data);
          }
        })
        .catch((err) => {
          setLoadingTable(false);
          if ([401, 403].includes(err.response.status)) {
            toast.error("A sua sessão expirou, para continuar faça login novamente.")
            if(dataUser.key === 'ADMIN'){
              navigate(links.ADMIN_SIGNIN)
              setDataUser(null);
            }else{
              navigate(links.SIGNIN)
              setDataUser(null);
            }
          }
        });
  };

  const formatarParaMoeda = (value) => {
    if (!value) return "";
    let valorNumerico = value.replace('R$','').replace(',','.');
    let valorFloat = parseFloat(valorNumerico.trim());
    return valorFloat;
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(paymentResponse?.qr_code)
        .then(() => setCopied(true))
        .catch(err => console.error('Erro ao copiar para a área de transferência: ', err));
};

  return (
    <div class="container mw-100">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h1 className="h4 fw-bold">Mensalidades</h1>
        <div className="d-flex col-8 col-md-auto mb-2">
          <button className="custom-btn btn btn-outline-secondary"
          onClick={() => navigate(-1)}
          >VOLTAR</button>
        </div>
      </div>
      <Modal show={showModal} onHide={handleCloseModal} centered style={{ textAlign: 'center' }}>
        <Modal.Header closeButton className="modal-footer">
          <Modal.Title>Valor: {somaAtraso > 0 ? Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(somaAtraso) : Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(proximaParcela)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {paymentStatus ? (
            <img
              src={checkImage}
              alt="Pagamento Concluído"
              className="qr-code"
            />
          ) : paymentResponse?.qr_code ? (
            <img
              src={`data:image/png;base64,${paymentResponse?.qr_code_base64.replace(/"/g, '')}`}
              alt="QR Code"
              className="qr-code"
            />
          ) : (
            <p>QR Code não disponível.</p>
          )}
        </Modal.Body>
        <Modal.Footer className="modal-footer">
          { paymentStatus ? 
            <button className="custom-btn btn btn-primary d-flex w-100 h-100 text-center justify-content-center mb-2"
            >
              Pagamento Confirmado</button>
              :
            <button className="custom-btn btn btn-primary d-flex w-100 h-100 text-center justify-content-center mb-2" onClick={handleCopy} // Fecha a modal após o pagamento
            >
              <i className="bi bi-copy" style={{marginRight: '5px'}}></i>{copied ? 'Código copiado!' : 'Copiar código'}</button>
          }
        </Modal.Footer>
      </Modal>
      {/* Tabela */}
      <div className="card" style={{border: '1px solid rgba(0, 0, 0, 0.1)', borderRadius: '8px'}}>
        <div className="card-body">
          <MensalidadesTable updateTable={updateTable} listMensals={listMensals}/>
        </div>
      </div>
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </div>
  );
}