import React, { useState, useContext } from 'react';
import moment from 'moment';
import axios from "axios";
import { Popover, Button, OverlayTrigger, Modal, Form, Toast } from 'react-bootstrap';
import "./MensalidadesTable.css";
import { AuthContext } from '../../../../../contexts/AuthContext';
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import * as links from "../../../../../utils/links";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MensalidadesTable = ({ listMensals, updateTable }) => {
  // Estados para controle do modal e do popover
  const [showModal, setShowModal] = useState(false);
  const [selectedMensal, setSelectedMensal] = useState(null);
  const [selectedDelete, setSelectedDelete] = useState(null);
  const { setDataUser, authInfo } = useContext(AuthContext);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const { dataUser } = authInfo;
  let navigate = useNavigate();
  const token = authInfo?.dataUser?.token;

  // Estado para controlar qual popover está visível (identificado pelo índice)
  const [activePopover, setActivePopover] = useState(null);

  // Função para abrir o modal com os dados da linha
  const handleEdit = (mensal) => {
    setSelectedMensal(mensal);
    setShowModal(true);
    setActivePopover(null); // Fecha o popover ao abrir o modal
  };

  // Função para fechar o modal
  const handleClose = () => {
    setShowModal(false);
    setSelectedMensal(null);
    setActivePopover(null); // Fecha o popover ao fechar a modal
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedMensal((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const updateParcela = (selectedMensal) => {
    let body = {
      dataDeVencimento: selectedMensal.dataDeVencimento,
      dataDeRenovacao: selectedMensal.dataDeRenovacao,
      valor: selectedMensal.valor,
      status: selectedMensal.status,
      dataDoPagamento: selectedMensal.dataDoPagamento,
      diaPagamento: selectedMensal.diaPagamento,
    };
    axios
      .put(`${process.env.REACT_APP_SERVIDOR}/parcela/${selectedMensal?.id}`, body, {
        headers: {
          "x-access-token": token,
          "content-type": "application/json",
        },
      })
      .then((res) => {
        toast.success("Alterações realizadas com sucesso!");
        handleClose();
        updateTable();
      })
      .catch((err) => {
        if ([401, 403].includes(err.response.status)) {
          toast.error("A sua sessão expirou, para continuar faça login novamente.");
          if (dataUser.key === "ADMIN") {
            navigate(links.ADMIN_SIGNIN);
            setDataUser(null);
          } else {
            navigate(links.SIGNIN);
            setDataUser(null);
          }
        }
        toast.error(`${err?.response?.data?.error}`);
      });
  };

  const deleteParcela = (selectedMensal) => {
    axios
      .delete(`${process.env.REACT_APP_SERVIDOR}/parcela/${selectedMensal?.id}`, {
        headers: {
          "x-access-token": token,
        },
      })
      .then((res) => {
        toast.success(`${res?.data?.message}`);
        setShowDeleteConfirm(false);
        setShowModal(false);
        updateTable();
      })
      .catch((err) => {
        toast.error(`${err?.response?.data?.error}`);
      });
  };

  const handleDelete = () => {
    deleteParcela(selectedDelete);
  };

  return (
    <>
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <table className="table table-bordered">
        <thead>
          <tr>
            <th className="text-muted">Data de Vencimento</th>
            <th className="text-muted">Valor</th>
            <th className="text-muted">Status</th>
            <th className="text-muted">Data de Renovação</th>
            <th className="text-muted">Data de Pagamento</th>
          </tr>
        </thead>
        <tbody>
          {listMensals?.map((canal, index) => (
            <tr key={index}>
              <td>{moment.utc(canal?.dataDeVencimento)?.format("DD/MM/YYYY")}</td>
              <td>
                {new Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                }).format(canal?.valor)}
              </td>
              <td>
                <span
                  className={`badge ${
                    canal?.status === "ABERTO"
                    ? "bg-light"
                    : canal?.status === "PAGO"
                    ? "bg-success"
                    : canal?.status === "VENCIDO"
                    ? "bg-warning"
                    : "bg-info"
                  }`}
                  >
                  {canal?.status ?? "-"}
                </span>
              </td>
              <td>{moment.utc(canal?.dataDeRenovacao)?.format("DD/MM/YYYY")}</td>
              <td>
                {canal?.dataDoPagamento
                  ? moment(canal?.dataDoPagamento)?.add(3, "hours")?.format("DD/MM/YYYY HH:mm:ss")
                  : "-"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Modal de edição */}
      <Modal show={showModal} onHide={handleClose} centered style={{ textAlign: "center" }}>
        <Modal.Header closeButton className="modal-footer">
          <Modal.Title>{showDeleteConfirm ? "Excluir Mensalidade" : "Editar Mensalidade"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedMensal ? (
            <Form>
              <Form.Group controlId="formDataDeVencimento">
                <Form.Label>Data de Vencimento</Form.Label>
                <Form.Control
                  type="date"
                  name="dataDeVencimento"
                  value={moment(selectedMensal.dataDeVencimento).format("YYYY-MM-DD")}
                  onChange={handleInputChange}
                />
              </Form.Group>

              <Form.Group controlId="formDataDeRenovacao" className="mt-3">
                <Form.Label>Data de Renovação</Form.Label>
                <Form.Control
                  type="date"
                  name="dataDeRenovacao"
                  value={moment(selectedMensal.dataDeRenovacao).format("YYYY-MM-DD")}
                  onChange={handleInputChange}
                />
              </Form.Group>

              <Form.Group controlId="formValor" className="mt-3">
                <Form.Label>Valor</Form.Label>
                <Form.Control
                  type="number"
                  name="valor"
                  value={selectedMensal.valor}
                  onChange={handleInputChange}
                />
              </Form.Group>

              <Form.Group controlId="formDataDePagamento" className="mt-3">
                <Form.Label>Data de Pagamento</Form.Label>
                <Form.Control
                  type="date"
                  name="dataDoPagamento"
                  value={moment(selectedMensal.dataDoPagamento).format("YYYY-MM-DD")}
                  onChange={handleInputChange}
                />
              </Form.Group>

              <Form.Group controlId="formDiaPagamento" className="mt-3">
                <Form.Label>Dia de Pagamento</Form.Label>
                <Form.Control
                  type="text"
                  name="diaPagamento"
                  value={selectedMensal.diaPagamento}
                  onChange={handleInputChange}
                />
              </Form.Group>

              <Form.Group controlId="formStatus" className="mt-3">
                <Form.Label>Status</Form.Label>
                <Form.Select
                  value={selectedMensal.status}
                  onChange={handleInputChange}
                  className="form-control"
                  style={{ width: "100%", borderRadius: "8px" }}
                >
                  <option value="ABERTO">ABERTO</option>
                  <option value="PAGO">PAGO</option>
                  <option value="VENCIDO">VENCIDO</option>
                </Form.Select>
              </Form.Group>
            </Form>
          ) : showDeleteConfirm ? (
            <p style={{ fontWeight: "bold" }}>
              Tem certeza disso? Ao confirmar, esta mensalidade será excluída permanentemente.
            </p>
          ) : (
            ""
          )}
        </Modal.Body>
        <Modal.Footer className="modal-footer">
          {/* Botões de Confirmação de Exclusão ou Cancelamento */}
          {showDeleteConfirm ? (
            <>
              <Button variant="secondary" onClick={handleClose}>
                Cancelar
              </Button>
              <Button variant="danger" onClick={handleDelete}>
                Confirmar Exclusão
              </Button>
            </>
          ) : (
            <>
              <Button variant="secondary" onClick={handleClose}>
                Cancelar
              </Button>
              <Button variant="primary" onClick={() => updateParcela(selectedMensal)}>
                Salvar
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MensalidadesTable;
